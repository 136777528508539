import { useRef } from "react";

import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";

import Style from "../Menu.module.scss";
import { useHeader } from "../../../../logic/useHeader";
import { useProducts } from "../../../../logic/useProducts";
import { currentSelectedProduct } from "../../../../store/slices/ProductsSlice";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

export const MobileNav = () => {
  const location = useLocation();
  const parse = require("html-react-parser");
  const { getProductItem } = useProducts();
  const { menu } = useHeader();
  const showMenu = useRef();
  const dispatch = useDispatch();
  const handleShowmenu = () => {
    const menuOuter = showMenu.current;
    menuOuter.classList.add(`${Style.show_menu}`);
    document.querySelector("body").classList.add("show_menu");
    document.querySelector("html").classList.add("show_menu");
  };
  const handleClosemenu = () => {
    const menuOuter = showMenu.current;
    menuOuter.classList.remove(`${Style.show_menu}`);
    document.querySelector("body").classList.remove("show_menu");
    document.querySelector("html").classList.remove("show_menu");
  };
  return (
    <>
      <div className={Style.hamburgur_menu} onClick={handleShowmenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`${Style.navbar_mobile}`} ref={showMenu}>
        <div className={Style.navbar_mobile_header}>
          <div className="container">
            <div className={Style.navbar_mobile_header_title}>Menu</div>
            <div
              className={Style.navbar_mobile_header_close}
              onClick={handleClosemenu}
            >
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div className={Style.navbar_mobile_wrapper}>
          <div className="container">
            <ul>
              {menu?.map((value, index) => {
                return (
                  <li key={index}>
                    <Accordion className="navigation_bar">
                      <Accordion.Item eventKey="0">
                        {value?.sub_menu?.[0] ? (
                          <Accordion.Header>
                            {parse(value?.title)}
                          </Accordion.Header>
                        ) : (
                          <li
                            className={
                              location.pathname.substring(1) === value?.slug
                                ? Style.active
                                : ""
                            }
                          >
                            <Link
                              to={`/${value?.slug}`}
                              onClick={handleClosemenu}
                            >
                              {parse(value?.title)}
                            </Link>
                          </li>
                        )}

                        <Accordion.Body>
                          <ul>
                            {value?.sub_menu?.map((item, index) => {
                              return (
                                <li
                                  key={index}
                                  className={
                                    location.pathname.substring(1) ===
                                    item?.slug
                                      ? Style.active
                                      : ""
                                  }
                                >
                                  <Link
                                    to={`/${item?.slug}`}
                                    onClick={() => {
                                      getProductItem(item?.slug);
                                      handleClosemenu();
                                      dispatch(
                                        currentSelectedProduct(item?.slug)
                                      );
                                    }}
                                  >
                                    {parse(item?.title)}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </li>
                );
              })}
              <li>
                <Accordion className="navigation_bar">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Open an Account</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          <a
                            href={"https://live.meon.co.in/pentad/individual"}
                            onClick={handleClosemenu}
                            target={"_blank"}
                          >
                            <span>Trading & Demat Account</span>
                          </a>
                        </li>
                        <li>
                          <Link
                            to="mutualfund-offline"
                            onClick={handleClosemenu}
                          >
                            <span>Mutual Fund</span>
                          </Link>
                        </li>
                        {/* <li>
                          <a
                            href={"/"}
                            onClick={handleClosemenu}
                            target={"_blank"}
                          >
                            <span>Global</span>
                          </a>
                        </li> */}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </li>
              <li>
                <Accordion className="navigation_bar">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Login</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          <a
                            href="https://pen.pentadsecurities.com/"
                            onClick={handleClosemenu}
                            target={"_blank"}
                          >
                            Pen
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://pentad.webappreports.com/login.php#"
                            onClick={handleClosemenu}
                            target={"_blank"}
                          >
                            Book
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://wealthelite.in/client-login"
                            onClick={handleClosemenu}
                            target={"_blank"}
                          >
                            Box
                          </a>
                        </li>
                        {/* <li>
                          <a
                            href="/"
                            onClick={handleClosemenu}
                            target={"_blank"}
                          >
                            Global Investing
                          </a>
                        </li> */}
                        <li>
                          <a
                            href={
                              "https://evoting.cdslindia.com/Evoting/EvotingLogin"
                            }
                            target={"_blank"}
                          >
                            Evoting
                          </a>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
